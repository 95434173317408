import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { confirmAlert } from '../utils/react-confirm-alert';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import '../../styles/css/modalstyles.css';
import {base} from "../../base";


class AgeVerification extends Component {
    constructor() {
        super()
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            loading: true,
            redirect: false,
            modal: true,
            verifiedAge: false,
            user_age: sessionStorage.getItem('verifiedAge') || false
        }
    }

    componentDidMount() {
        this.props.loadPage();
    }

    handleChange (evt) {
      this.setState({ [evt.target.name]: evt.target.value });
    }

    submit_age_verification(age_to_verify){
        const year = this.state.year;
        const month = this.state.month;
        const day = this.state.day;
        const legalAge = parseInt(age_to_verify, 10);
        const tenantVariables = this.props.variables || {};
        const stringConstants = this.props.stringConstants || {};
        this.setState({
            modal: false
        })
        if(!year || !month || !day){
            confirmAlert({
                variables: tenantVariables,
                title: stringConstants.INPUTERRORTITLE,
                message: stringConstants.INPUTERRORENTERALLINPUTS,
                buttons: [
                    {
                        label: stringConstants.OKTEXT,
                        onClick: () => {
                            this.setState({
                                modal:true
                            })
                        }
                    }
                ]
            })
            return;
        }
        var bday = [
            parseInt(year, 10),
            parseInt(month, 10) || 1,
            parseInt(day, 10) || 1
        ].join('/')
        var age = ~~((new Date().getTime() - +new Date(bday)) / (31557600000))

        if (age >= legalAge) {
            try {
                sessionStorage.setItem('birthday', bday);
                sessionStorage.setItem('verifiedAge', age);
            } catch(e){
              console.log(e)
              alert(stringConstants.NOTENOUGHSPACEERROR)
                return
            }
            if(sessionStorage.getItem('userEmail') || this.state.userEmail){
                const email = sessionStorage.getItem('userEmail') || this.state.userEmail;
                const userEmailToBase64 = btoa(email);
                base.post('users/'+userEmailToBase64+'/birthday' , {
                    data:bday,
                    then(err){
                        console.log(err)
                    }
                })
            }
        } else {
            confirmAlert({
                variables: tenantVariables,
                title: '',
                message: stringConstants.UNABLETOPROCESSSUBMISSION,
                buttons: [
                    {
                        label: stringConstants.OKTEXT,
                        onClick: () => {
                            this.setState({
                                modal:true
                            })
                        }
                    }
                ]
            })
        }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const stringConstants = this.props.stringConstants || {};
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        const verifiedAge = this.props.checkForAgeGate(tenantVariables);
        if (verifiedAge) {
            return (
                <Redirect to="/" />
            )
        }

        const primaryColor = tenantVariables.primaryColor || '#000'
        const secondaryColor = tenantVariables.secondaryColor || '#000'
        const submitButtonText = stringConstants.SUBMITBUTTONTEXT || "Submit";

        return (
          <div className="main-container-login" style={{backgroundColor:"black"}}>
            <Modal isOpen={this.state.modal} style={{width: '90%', textAlign: "center"}} id="ageGateModal">
              <ModalHeader style={{color: secondaryColor, marginBottom: '10px'}}>{stringConstants.CONFIRMYOURAGETEXT}</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form id="user-values-form">
                        <div className="input-group">
                          <div className="col-md-4 col-sm-12">
                            <input id="month" name="month" type="number" className="form-control" value={this.state.month} onChange={this.handleChange} placeholder="MM" min='1' max='12'/>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <input id="day" name="day" type="number" className="form-control" value={this.state.day} onChange={this.handleChange} placeholder="DD" min='1' max='31'/>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <input id="year" name="year" type="number" className="form-control" value={this.state.year} onChange={this.handleChange} placeholder="YYYY" min="1900" max="2019"/>
                          </div>
                        </div>
                        <button type="button" className="btn btn-default btn-admin" onClick={() => { this.submit_age_verification(variable_age) }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem'}}>{submitButtonText}</button>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
          </div>
        )
    }
}

export default AgeVerification
